/**
 * Google Maps Polygon styles
 */
.gm-style-pbc + div div:nth-child(3) div:nth-child(1) > div > div {
  background-color: white !important;
  border-color: #f5821f !important;
  border-radius: 50% !important;

  padding: 2px !important;
  box-sizing: content-box !important;
  margin-left: -2px !important;
  margin-top: -2px !important;
}

.gm-style-pbc + div div:nth-child(3) div:nth-child(2) > div > div {
  background-color: white !important;
  border-color: #f5821f !important;
  border-radius: 50%;
  opacity: 1 !important;
  padding: 1px !important;
  box-sizing: content-box !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
}
.gm-style-pbc + div div:nth-child(3) div:nth-child(2) > div > div:before {
  content: '+' !important;
  position: absolute !important;
  top: -3px !important;
  left: 2px !important;
  font-size: 0.9rem !important;
  color: #f5821f !important;
}
