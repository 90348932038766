@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap';

body {
  margin: 0px;
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  /* scrollbar-width: thin;
  scrollbar-color: #f5821f transparent; */
}
/* 
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #f5821f;
  border-radius: 4px;
} */
